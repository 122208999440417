/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import Content from './Content'
import Link from './Link'
import StaggerHeading from './StaggerHeading'

// animation setup
// ==========================================================================================
const revealFrames = keyframes`
0% {
  opacity:0;
}
100% {
  opacity: 1;
}`

// styles
// ==========================================================================================
const Wrapper = styled.div`
  position: relative;
  height: ${({ hasImage }) => (hasImage ? '750px' : 'auto')};
  margin-top: ${({ hasImage }) => (hasImage ? 'auto' : '104px')};
  font-family: ${({ theme }) => theme.fonts.feature};
  color: ${({ hasImage, theme }) => (hasImage ? '#fff' : theme.colors.navy)};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: ${({ hasImage }) => (hasImage ? 'auto' : '104px')};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: ${({ hasImage }) => (hasImage ? 'auto' : '216px')};
  }
`
const Label = styled.h5`
  color: ${({ hasImage, theme }) => (hasImage ? '#fff' : theme.colors.primary)};
  animation: ${revealFrames} 0.6s linear;
  animation-delay: 0.4s;
  animation-fill-mode: both;
`
const HeadingWrapper = styled.div`
  ${StaggerHeading} {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
      opacity: ${({ hide }) => (hide ? 0 : 1)};
      transition: opacity 0.3s, visibility 0.3s;
    }
    @media (min-width: 2500px) {
      visibility: visible;
      opacity: 1;
    }
  }
`
// component
// ==========================================================================================
const Hero = () => {
  // const [hasVideoEnded, setHasVideoEnded] = useState(false)
  const hasImage = false
  const contactLink = {
    meta: {
      id: 'XjLrIhAAACQAwpN5',
      uid: 'contact',
      type: 'contact',
    },
  }

  return (
    <Wrapper hasImage={hasImage}>
      <Content>
        <Row middle="xs">
          <Col xs={8} xsOffset={1} md={7}>
            <Label hasImage={hasImage}>Error 404</Label>
            <HeadingWrapper>
              {/* <h1>The page you're looking for cannot be found</h1> */}
              <StaggerHeading heroTitle="The page you're looking for cannot be found" />
              <p>
                Please feel free to <Link link={contactLink}>contact us</Link>{' '}
                if there is anything we can help you with.
              </p>
            </HeadingWrapper>
          </Col>
        </Row>
      </Content>
    </Wrapper>
  )
}

export default Hero
