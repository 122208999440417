import React from 'react'

import NotFound from '../components/NotFound'
import Layout from '../components/Layout'

const NotFoundPage = () => {
  return (
    <Layout>
      <NotFound />
    </Layout>
  )
}

export default NotFoundPage
