import React from 'react'
import styled, { css, keyframes } from 'styled-components'

import { asText } from '../utils/prismic'

// animation setup
// ==========================================================================================
function createRevealAnimation(count) {
  const styles = []

  for (let i = 0; i <= count; i++) {
    styles.push(`
       &:nth-child(${i}) span {
         animation-delay: ${i * 20 + 200}ms;
       }
     `)
  }
  return css`
    ${styles.join('')}
  `
}
const revealFrames = keyframes`
0% {
  opacity:0;
  transform:translate3d(0, 100%, 0);
}
100% {
  opacity: 1;
  transform:translate3d(0, 0, 0);
}`

// styles
// ==========================================================================================
// const easeOutQuad = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)'
// const easeInOutSine = 'cubic-bezier(0.445, 0.05, 0.55, 0.95)'
const easeInOutQuad = 'cubic-bezier(0.455, 0.03, 0.515, 0.955)'
const Word = styled.span`
  float: left;
  /* prevent devcenders being cut off */
  padding-bottom: 0.07em;
  margin-top: -0.07em;
  overflow: hidden;
`
const WordInner = styled.span`
  display: block;
  animation: ${revealFrames} 0.3s ease-in-out;
    animation-fill-mode: both;
  /* transform: translate3d(0, 100%, 0);
  transition: transform 0.5s ${easeInOutQuad}; */
`
const Title = styled.h1`
  &::after {
    display: block;
    content: '';
    clear: both;
  }
  ${Word} {

    ${({ str }) => createRevealAnimation(str.length)}
  }
  /* ${({ string }) =>
    string.map(
      (_, i) => `
        ${Word}:nth-child(${i + 1}) span {
          transform: translate3d(0, 0, 0);
          transition-duration: ${600 + i * 10}ms;
          transition-delay: ${i * 40}ms;
        }
      `
    )} */
`
// component
// ==========================================================================================
const StaggerHeading = ({ heroTitle }) => {
  let titleCopy = asText(heroTitle)

  if (!titleCopy || titleCopy.length < 1) {
    return null
  }

  titleCopy = titleCopy.split(' ')

  return (
    titleCopy && (
      <Title str={titleCopy}>
        {titleCopy.map((word, i) => (
          <Word key={i}>
            <WordInner>{word}&nbsp;</WordInner>
          </Word>
        ))}
      </Title>
    )
  )
}

export default StaggerHeading
